<template>
	<el-dialog :visible="visible" title="设置门店地址" width="746px" :before-close="handleClose">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
			<el-form-item prop="addressList" label="设置门店地址">
				<el-cascader style="width: 436px;" :props="props" :options="dataList" clearable placeholder="请选择省/市/区"
					@change="handleAddressChange" v-model="ruleForm.addressList" maxlength="100" />
			</el-form-item>
			<el-form-item prop="Address">
				<el-input type="textarea" v-model="ruleForm.Address" @blur="handleBlur" style="width: 436px;"
					rows="3" placeholder="请设置详细地址"></el-input>
				<!-- <el-button type="primary" style="margin-left: 40px;" @click="handleLocation">搜索</el-button> -->
			</el-form-item>
			<!-- <el-form-item>
				<div class="BaiduMap">
					<div id="result" class="amap_lib_placeSearch" v-show="hide">
						<div class="amap_lib_placeSearch_list amap-pl-pc" v-for="(item,index) in poiArr"
							@click="openMarkerTipById(index,$event)"
							@mouseout="onmouseout_MarkerStyle(index+1,$event)" :key="index">
							<div class="poibox" style="border-bottom: 1px solid #eaeaea">
								<div class="amap_lib_placeSearch_poi poibox-icon"
									:class="index==selectedIndex?'selected':''">{{index+1}}</div>
								<div class="poi-img" v-if="item.url"
									:style="'background-image:url('+item.url+'?operate=merge&amp;w=90&amp;h=56&amp;position=5)'">
								</div>
								<div class="poi-title">
									<p class="poi-name">{{item.name}}</p>
								</div>
								<div class="poi-info">
									<p style='color:#999;'>地址：{{item.address}}</p>
									<p v-if="item.tel!=''" style='color:#999'>电话：{{item.tel}}</p>
								</div>
								<div class="clear"></div>
							</div>
						</div>
					</div>
					<div class="amap-panel" id="panel"></div>
					<div class="amap-container" id="container">
					</div>
				</div>
			</el-form-item> -->
			<el-form-item label="设置门店经纬度" prop="Longitude" :rules="rules.lonlat">
				<div class="flex flex-align-center gap-5">
					<el-input style="width:180px" placeholder="经度" v-model="ruleForm.Longitude"
						 oninput="this.value = this.value.replace(/[^0-9.]/g, '')"></el-input>
					<span>，</span>
					<el-input style="width:180px" placeholder="纬度" v-model="ruleForm.Latitude"
						 oninput="this.value = this.value.replace(/[^0-9.]/g, '')"></el-input>
				</div>
				<div class="color-999999">设置门店地址经纬度，用户选择门店时，可根据用户所在位置给其推荐距离最近的门店</div>
			</el-form-item>
		</el-form>
		
		<div slot="footer" class="dialog-footer">
		    <el-button @click="handleClose">取 消</el-button>
		    <el-button type="primary" @click="handleSave">保 存</el-button>
		</div>
		
	</el-dialog>
</template>

<script>
	import axios from 'axios'
	import CryptoJS from 'crypto-js'
	import jsonp from 'jsonp'
	export default {
		components: {},
		props: {
			visible: false,
			longLat: {
				type: Array,
				default: []
			},
			districtList: {
				type: Array,
				default: []
			},
			addressStr: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				
				dataList: [],
				ruleForm: {
					addressList: [],
					ProvinceId: '',
					CityId: '',
					AreadId: '',
					Province: '',
					City: '',
					Area: '',
					Address: '',
					Longitude:'',
					Latitude:'',
				},
				rules: {
					addressList: [{
						required: true,
						message: '请选择地址',
						trigger: 'change'
					}],
					Address: [{
						required: true,
						message: '请填写详细地址，并在地图的搜索结果中选取坐标',
						trigger: 'blur'
					}],
					lonlat:[{
						validator: (rule, value, callback)=>{
							if([this.ruleForm.Longitude,this.ruleForm.Latitude].filter(v=>v).length==1){
								callback(new Error('请设置完整经纬度信息'))
							}
							callback()
						},
						trigger: 'blur'
					}]
				},
				
				address: '',
				map1: '',
				poiArr: [], //左边搜索出来的数组
				windowsArr: [], //信息窗口的数组
				centerList: [],
				selectedIndex: -1,
				hide: false,
				clickType: 1,
				marList: [],
				dataCityList: [],
				
				clickPoint:null,
				key:'3G5BZ-CO4HQ-TI357-BJPP5-EBNOJ-DNF5I',
				SecretKey:'KazH28S1wsPLR9t0u0X26N8iKFutWEpD'
			}
		},
		watch: {
			visible(){
				if (this.visible){
					// this.initMap();
					[this.ruleForm.Latitude,this.ruleForm.Longitude] = this.longLat
					this.handleAddressChange()
					// console.log(this.ruleForm,123)
				}
			},
			districtList(val){
				this.ruleForm.addressList = val;
				
			},
			addressStr(val){
				this.ruleForm.Address = val;
			}
		},
		created() {
		},
		mounted() {
			//把jsonp回调放到window上 不然会访问不到
			// window.jsonpCallBack = this.jsonpCallBack

			this.getAddressList();
		},
		methods: {
			initMap(){
				var that = this;
				this.$nextTick(function() {
					let [lat=39.90923,lng=116.397428] = this.longLat
					this.center = {
						lat: lat,
						lng: lng
					}
					let center = new TMap.LatLng(lat,lng)
					that.mapObj = new TMap.Map('container', {
						// resizeEnable: true, //是否监控地图容器尺寸变化
						zoom: 15, //初始化地图层级
						center: center,//初始化地图中心点
						pitch: 43.5,  //设置俯仰角
						rotation: 0    //设置地图旋转角度
					});
					// that.geocoder = new TMap.Geocoder({
					// 	radius: 500 //范围，默认：500
					// });
					that.marker = new TMap.MultiMarker({
						id:'container',
						map:that.mapObj,
						styles: {
							// 点标记样式
							marker: new TMap.MarkerStyle({
								width: 10, // 样式宽
								height: 15, // 样式高
								anchor: { x: 5, y: 15 }, // 描点位置
							}),
						},
					});
					that.marker.setGeometries([{
						position:center
					}]);
					// that.mapObj.add(that.marker);
				
					// that.initEvent();


					// console.log(this.longLat)
					// if (this.longLat.length === 2){
					// 	this.center = {
					// 		lat: this.longLat[0],
					// 		lng: this.longLat[1]
					// 	}
						
					// 	that.marker.setGeometries([{
					// 		position:new TMap.LatLng(this.center.lat,this.center.lng)
					// 	}]);
					// }
					
					this.handleAddressChange();
				
				})
			},
			//获取地址数据
			getAddressList() {
				var that = this;
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime()).then(res => {
					that.dataList = res.data;
				})
			},
			handleAddressChange() {
				this.ruleForm.ProvinceId = this.ruleForm.addressList[0]
				this.ruleForm.CityId = this.ruleForm.addressList[1]
				this.ruleForm.AreadId = this.ruleForm.addressList[2]
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ProvinceId) {
						this.ruleForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.ruleForm.CityId) {
								this.ruleForm.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.AreadId) {
										this.ruleForm.Area = lastitems.name
									}
								})
							}
						})
					}
				})
			
				
				console.log(this.ruleForm)
			
			},
			handleBlur() {
				// this.center = {
				// 	lng: 0,
				// 	lat: 0
				// }
			},
			//搜索
			async handleLocation() {
				try{

					if (!this.ruleForm.Province || !this.ruleForm.City || !this.ruleForm.Area || !this.ruleForm.Address) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请填写地址'
						})
						return;
					} 
					var address = this.ruleForm.Province + this.ruleForm.City + this.ruleForm.Area + this.ruleForm.Address
					let data = {
						key:this.key,
						address:address,
						output:'jsonp',
						callback:'jsonpCallBack',
					}
					let argument = Object.keys(data).sort((a, b) => a.localeCompare(b))
					// console.log(CryptoJS) 
					// return
					data.sig = CryptoJS.MD5('/ws/geocoder/v1/?'+argument.map(v=>`${v}=${data[v]}`).join('&')+this.SecretKey).toString()
					// console.log('https://apis.map.qq.com/ws/geocoder/v1/?'+argument.map(v=>`${v}=${data[v]}`).join('&')+this.SecretKey)
					// console.log(data.sig)
					let newArgument = Object.keys(data).sort((a, b) => a.localeCompare(b)).map(v=>{
						return `${v}=${encodeURI(data[v])}`
					}).join('&')
					let url = `https://apis.map.qq.com/ws/geocoder/v1/?` + newArgument
					// console.log(url)
					// let res = await axios({
					// 	method: 'get',
					// 	url:url,
					// 	headers: {
					// 		'Access-Control-Allow-Origin': '*',
					// 	},
					// })
					// console.log(data)
            // 创建script标签进行跨域请求
            var script = document.createElement('script');
            script.src = url
            document.body.appendChild(script);

					// console.log(res)
					return 
					// else if (!this.ruleForm.Address) {
					// 	this.marker.setMap(null);
					// 	this.selectedIndex = -1
					// 	this.hide = false
					// 	this.marker.remove(this.marList)
					// 	this.marker.remove(this.windowsArr)
					// 	this.centerList = []
					// 	this.marList = []
					// 	this.windowsArr = []
					// 	return;
					// }
				
				
					this.marker.setMap(null);
					this.marker.remove(this.marList)
					this.marker.remove(this.windowsArr)
					this.centerList = []
					this.marList = []
					this.windowsArr = []
				
					let areaCode = 0
					this.dataList.forEach(t => {
						if (this.ruleForm.Province == t.name) {
							t.child.forEach(tt => {
								if (tt.name == this.ruleForm.City) {
									tt.child.forEach(ttt => {
										if (ttt.name == this.ruleForm.Area) {
											areaCode = ttt.id
										}
									})
								}
							})
						}
					})
					// this.placeSearchName(areaCode, this.ruleForm.Address)
			
				}catch(e){
					console.log(e,123)
				}
			},
			//jsonp回调
			jsonpCallBack(res){
				if(res.status==0){
					// console.log(res.result)
					this.mapObj.setCenter({
						lng:res.result.location.lng,
						lat:res.result.location.lat,
					})
					this.center = res.result.location
					var point = new TMap.LatLng(res.result.location.lat,res.result.location.lng)
					// console.log(that.clickPoint)
					this.marker.setGeometries([{
						position:point,
						properties:{
							title:res.result.title
						}
					}]);
				}else{
					this.$message.error(res.message)
				}
			},
			// 渲染列表
			placeSearchName(areaCode, searchName) {
				// console.log(areaCode, searchName)
				this.hide = true
				var MSearch;
				this.mapObj.plugin(
					["TMap.PlaceSearch", "TMap.Scale"],
					() => {
						MSearch = new TMap.PlaceSearch({
							// pageSize: 20,
							city: areaCode, // 兴趣点城市
							citylimit: true, //是否强制限制在设置的城市内搜索
						});
						TMap.event.addListener(MSearch, "complete", this.keywordSearch_CallBack) //返回地点查询结果
						MSearch.search(searchName); //关键字查询
					}
				);
			},
			//结果的回调
			keywordSearch_CallBack(data) {
				var poiArr = data.poiList.pois
				var resultCount = poiArr.length
			
				if (poiArr.length) {
					this.poiArr = []
					this.poiArr = poiArr; //渲染列表
					for (var i = 0; i < resultCount; i++) {
						this.addmarker(i, poiArr[i])
						this.poiArr[i].url = this.poiArr[i].photos ? this.poiArr[i].photos[0] ? this.poiArr[i].photos[0]
							.url : "" : ""
					}
					this.mapObj.setFitView()
					this.selectedIndex = 0
					this.windowsArr[0].open(this.mapObj, this.centerList[0])
					this.center.lng = this.poiArr[0].location.lng;
					this.center.lat = this.poiArr[0].location.lat;
					this.getAddress();
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: '地址查询位置失败'
					})
				}
			},
			//添加marker&infowindow
			addmarker(i, d) {
			
				var lngX = d.location.getLng();
				var latY = d.location.getLat();
				this.centerList.push(new TMap.LngLat(lngX, latY));
			
				var markerOption = {
					map: this.mapObj,
					position: new TMap.LngLat(lngX, latY)
				};
				var mar = new TMap.Marker(markerOption);
				this.marList.push(mar);
			
				var infoWindow = new TMap.InfoWindow({
					content: "<h3>" + d.name + "</h3>" + this.TipContents(d.name, d.address),
					size: new TMap.Size(300, 0),
					autoMove: true,
					offset: new TMap.Pixel(0, -20)
				});
				this.windowsArr.push(infoWindow);
			
				var _this = this;
				var aa = (e) => {
					this.clickType = 2
					var obj = mar.getPosition();
					this.center = {
						lat: obj.lat,
						lng: obj.lng
					}
					this.selectedIndex = i
					this.getAddress()
					this.address = d.name + d.address //展示名字
					infoWindow.open(_this.mapObj, obj);
				}
				TMap.event.addListener(mar, "click", aa)
			},
			TipContents(name, address) {
				//窗体内容
				if (
					name == "" ||
					name == "undefined" ||
					name == null ||
					name == " undefined" ||
					typeof name == "undefined"
				) {
					type = "暂无";
				}
				if (
					address == "" ||
					address == "undefined" ||
					address == null ||
					address == " undefined" ||
					typeof address == "undefined"
				) {
					address = "暂无";
				}
				var str = `地址：${address}`
				return str
			},
			//列表鼠标移开后点样式恢复
			onmouseout_MarkerStyle(pointid, event) {
				event.currentTarget.style.background = ""
			},
			//点列表
			openMarkerTipById(pointid, event) {
				this.clickType = 1
				event.currentTarget.style.background = "#CAE1FF";
				this.selectedIndex = pointid
				this.windowsArr[pointid].open(this.mapObj, this.centerList[pointid])
				this.center = {
					lng: this.poiArr[pointid].location.lng,
					lat: this.poiArr[pointid].location.lat
				}
				this.getAddress()
			},
			//点地图容易点
			initEvent() {
				this.clickType = 3
				this.selectedIndex = -1
				var that = this;
				// console.log(this.mapObj)
				this.mapObj.addListener('click', function(e) { //添加点击事件,传入对象名，事件名，回调函数
					// console.log(e)
					that.clickPoint = e
					that.map1 = e.latLng;
					that.hide = false
					that.marker.remove(that.marList)
					that.marker.remove(that.windowsArr)
				  // that.marker.setMap(null);
					that.centerList = []
					that.marList = []
					that.windowsArr = []
			
					that.center = {
						lng: e.latLng.lng,
						lat: e.latLng.lat
					}
					that.mapObj.setCenter(that.center)

					// that.getAddress()

					setTimeout(() => {
						that.addPoint()
						let infowindow = new TMap.InfoWindow({
							content: "<h3>" + '当前地址' + "</h3>" + that.ruleForm.Address,
							autoMove: true,
							map:that.mapObj,
							position:new TMap.LatLng(that.center.lat,that.center.lng)
						})
					}, 100)
				})
			},
			addPoint() {
				var that = this;
				var point = new TMap.LatLng(this.center.lat,this.center.lng)
				// console.log(that.clickPoint)
				this.marker.setGeometries([{
					position:point
				}]);
				// this.mapObj.add(this.marker);
				// this.mapObj.setFitView(this.marker);
			
			},
			//逆地理编码
			async getAddress() {
				//这里说先不开
				try{
					let data = {
						location:`${this.center.lat},${this.center.lng}`,
						key:this.key,
						get_poi:1,
						poi_options:'address_format=short,radius=500,policy=1'
					} 
					let argument = Object.keys(data).map(v=>{
						return `${encodeURIComponent(v)}=${data[v]}`
					}).join('&')
					let url = `https://apis.map.qq.com/ws/geocoder/v1/?` + argument
					let res = await axios.get(url)
					console.log(res)
				}catch(err){
					console.log(err)
				}



					return 
				var point = [this.center.lng, this.center.lat];
				var that = this;
				that.geocoder.getAddress(point, function(status, result) {
					if (status === 'complete' && result.regeocode) {
						var address = result.regeocode.formattedAddress.split('');
						var provice = result.regeocode.addressComponent.province;
						var city = result.regeocode.addressComponent.city;
						var district = result.regeocode.addressComponent.district;
			
						address.splice(0, provice.length);
						address.splice(0, city.length);
						address.splice(0, district.length);
			
						that.filterAddress(result.regeocode.addressComponent.adcode);
						that.ruleForm.Address = that.address = address.join('')
					} else {
						that.$message({
							type: 'error',
							message: '地址获取失败, 请手动填写'
						})
					}
				});
			},
			filterAddress(id) {
				this.dataList.map(item => {
					item.child.map(items => {
						items.child.map(lastitems => {
							if (lastitems.id == id) {
								this.ruleForm.Province = item.name
								this.ruleForm.City = items.name
								this.ruleForm.Area = lastitems.name
								this.ruleForm.addressList = [item.id, items.id, lastitems.id]
								this.ruleForm.ProvinceId = item.id;
								this.ruleForm.CityId = items.id;
								this.ruleForm.AreadId = lastitems.id;
							}
						})
					})
				})
			},
			
			handleClose(){
				this.$emit('close')
			},
			handleSave(){
				// console.log(this.center,0)
				this.$refs['ruleForm'].validate((valid) => {
					if (valid){
						this.$emit('change',{
							...this.ruleForm,
							lat:this.ruleForm.Latitude,
							lon:this.ruleForm.Longitude,
						});
						this.handleClose();
					}
				})
			}
			
		}
	}
</script>

<style lang="less">
	.amap-container {
		width: 743px;
		height: 390px;
		border: 1px solid #ddd;
	}
	.amap-panel {}
	
	.amap_lib_placeSearch {
	
		position: absolute;
		left: 0px;
		top: 0px;
		max-height: 100%;
		width: 300px;
		background-color: white;
		overflow-y: auto;
		z-index: 9;
		border: 1px solid #ddd;
		// height: 100%;
		overflow-y: scroll;
	
		.poibox {
			border-bottom: 1px solid #eaeaea;
			cursor: pointer;
			padding: 5px 0 5px 10px;
			position: relative;
			min-height: 35px;
			line-height: 20px;
	
			.selected {
				background-image: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png) !important;
			}
	
			&:hover {
				background: #f6f6f6;
			}
	
			.poi-img {
				float: right;
				margin: 3px 8px 0;
				width: 90px;
				height: 56px;
				overflow: hidden;
			}
	
			.poi-title {
				margin-left: 25px;
				font-size: 13px;
				overflow: hidden;
			}
	
			.poi-info {
				word-break: break-all;
				margin: 0 0 0 25px;
				overflow: hidden;
	
				p {
					color: #999;
					margin: 0;
					line-height: 20px;
					font-size: 12px;
				}
			}
	
			.poibox-icon {
				margin-left: 7px;
				margin-top: 4px;
			}
	
			.amap_lib_placeSearch_poi {
				background: url(https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png) no-repeat;
				height: 31px;
				width: 19px;
				cursor: pointer;
				left: -1px;
				text-align: center;
				color: #fff;
				font: 12px arial, simsun, sans-serif;
				padding-top: 3px;
				position: absolute;
			}
		}
	}
	
	.amap-logo {
		display: none;
		opacity: 0;
	}
	
	.amap-copyright {
		opacity: 0;
	}
</style>
