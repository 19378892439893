<template>
	<div class="StoreInfo" style="padding-bottom:60px;">
		<!-- <div class="Title">门店信息</div> -->
		<div class="Contain">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="StoreInfo-rule-form">
				<el-form-item label="门店名称" prop="ShopName">
					<el-input v-model="ruleForm.ShopName" maxlength="20" placeholder="最多输入20个字" style="width: 300px;">
					</el-input>
				</el-form-item>
				<el-form-item label="门店编码">
					<el-input v-model="ruleForm.ShopNo" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item prop="addressList" label="门店地址" required>
					<!-- <el-cascader style="width: 300px;" :props="props" :options="dataList" clearable
						@change="handleAddressChange" v-model="ruleForm.AddressArr" maxlength="100" /> -->
					<div v-if="ruleForm.Province">
						{{ruleForm.Province+ruleForm.City+ruleForm.Area+ruleForm.Address}}
						<el-link type="primary" style="line-height: 1.15;margin-left: 20px;"
							@click="showSelectAddress=true">修改门店地址</el-link>
					</div>
					<div v-else>
						<el-link type="primary" style="line-height: 1.15;"
							@click="showSelectAddress=true">设置门店地址</el-link>
					</div>
					
					<div v-if="!trueAddress && ruleForm.Province" style="font-size: 12px;color: #F56C6C;line-height: 1;">地址的省市区数据已更新，请修改门店地址，重新选择省市区</div>
					
				</el-form-item>

				<el-form-item label="营业时间" required>
					<div class="time-box">
						<div class="time">
							<!--  -->
							<el-form-item style="width: 200px;" prop="startTime">
								<el-time-picker v-model="ruleForm.startTime" :default-value='timeDefaultShow'
									format="HH:mm" value-format="HH:mm" placeholder="起始时间"
									 @change="onTimeChange">
								</el-time-picker>
							</el-form-item>
						</div>
						<div style="margin: 0 20px;color:#666">至</div>
						<div class="time">
							<el-form-item style="width: 200px;" prop="endTime">
								<el-time-picker v-model="ruleForm.endTime" :default-value='timeDefaultShow'
									format="HH:mm" value-format="HH:mm"
									placeholder="结束时间" @change="changeEndtime">
								</el-time-picker>
							</el-form-item>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="门店标签" prop="tag">
					<el-select v-model="ruleForm.tag" placeholder="请选择" filterable clearable
						style="width: 495px;margin-right: 10px;">
						<el-option v-for="(item,index) in tagList" :key="index" :label="item.TagName" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系电话">
					<!-- <input v-model="ruleForm.areaCode" placeholder="区号"
						style="padding:0 10px;width:100px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						oninput="this.value=this.value.replace(/\D/g,'')" type="number">
					</input> -->
					<el-form-item style="display: inline-block;" prop="areaCode">
						<el-input v-model="ruleForm.areaCode" placeholder="区号" style="width:100px;"></el-input>
					</el-form-item>
					<span style="padding: 0 10px;color:#666">-</span>
					<el-form-item style="display: inline-block;" prop="phone">
						<!-- <input v-model="ruleForm.phone" placeholder="请输入门店联系电话"
							style="padding:0 10px;width:370px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
							oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number"
							maxlength="11">
						</input> -->
						<el-input v-model="ruleForm.phone" placeholder="请输入门店联系电话" maxlength="11" style="width:370px;"></el-input>
					</el-form-item>
				</el-form-item>
				<el-form-item label="门店配送" prop="IsOpenExpress">
					<!-- 
					<el-checkbox-group v-model="ruleForm.SeedType"> -->
					<div style="margin-bottom: 10px;">
						<el-checkbox v-model="ruleForm.IsOpenExpress">快递发货</el-checkbox>
						<div style="line-height: 1;font-size: 12px;color: #999;margin-left: 24px;margin-top: 0px;">
							客户选择该门店进入商城，支持在下单时选择快递发货服务</div>
					</div>

					<div style="margin-bottom: 10px;">
						<el-checkbox v-model="ruleForm.IsOpenSelfExtract">到店自提</el-checkbox>
						<div style="line-height: 1;font-size: 12px;color: #999;margin-left: 24px;margin-top: 0px;">
							客户进入商城下单，选择到店自提时，支持选择前往该门店提货</div>
					</div>
					<div>
						<span @click="showToast">
							<el-checkbox :disabled="ruleForm.Province?false:true" v-model="ruleForm.IsOpenInCityDelivery">
								同城配送</el-checkbox>
						</span>
						
						<div style="line-height: 1;font-size: 12px;color: #999;margin-left: 24px;margin-top: 0px;">
							客户选择该门店进入商城，支持在下单时选择同城配送服务，下单成功后，由该门店进行配送</div>
					</div>
					<!-- </el-checkbox-group> -->
				</el-form-item>
				<el-form-item label="门店负责人" v-if="ruleForm.IsOpenSelfExtract || ruleForm.IsOpenInCityDelivery">
					<el-select v-model="ruleForm.Principals" multiple filterable default-first-option
						placeholder="请选择门店负责人" style="width: 495px;">
						<el-option v-for="item in employeeList" :key="item.Id" :label="item.label" :value="item.Id">
						</el-option>
					</el-select>
					<div style="line-height: 1.5;font-size: 12px;color: #999;margin-top: 5px;">
						1.选择门店配送服务负责人，当客户选择到店自提或同城配送服务时，将通过企微「企店助手」给负责人推送通知
						<el-popover placement="top-start" title="" width="300" trigger="hover">
							<img src="https://cdn.dkycn.cn/images/melyshop/Group-18-12x.png" style="display: block;width: 100%;"></img>
							<el-link type="primary" style="font-size: 12px;margin-left: 5px;" slot="reference">查看示例
							</el-link>
						</el-popover>
					</div>
					<div style="line-height: 1.5;font-size: 12px;color: #999;">2.门店负责人可在员工工作台处理同城配送订单发货</div>
				</el-form-item>

				<div v-if="ruleForm.IsOpenInCityDelivery">
					<!-- <el-form-item label="同城配送方式">
						<el-checkbox v-model="ruleForm.InCityDeliveryType===0?true:false">门店自配送</el-checkbox>
					</el-form-item> -->
					<el-form-item label="收费标准">
						<el-checkbox v-model="ruleForm.ChargesStandard===0?true:false">按距离收取配送费</el-checkbox>
						<div style="line-height: 1.5;font-size: 12px;color: #999;margin-left: 24px;">
							以门店地址为起点，客户收货地址为终点，两点之间距离（非直线距离）计算配送费</div>
					</el-form-item>
					<el-form-item label="起送价" prop="StartPrice">
						商品消费满
						<el-input v-model="ruleForm.StartPrice" style="width: 100px;margin: 0 5px;"></el-input>
						元起送
					</el-form-item>
					<el-form-item label="配送费设置" required>
						<div class="flex-box">
							<el-form-item >
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.DeliveryFeeInitKm">
								</el-input>
							</el-form-item>
							公里以内（含），收取
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.InitKmDeliveryFee">
								</el-input>
							</el-form-item>
							元配送费，每超出
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.DeliveryFeePerBeyondKm">
								</el-input>
							</el-form-item>
							公里，增加
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.PerBeyondKmDeliveryFee">
								</el-input>
							</el-form-item>
							元配送费
						</div>
						
					</el-form-item>
					<el-form-item label="最远配送距离" required>
						<div class="flex-box">
							距门店地址，直线距离在
							<el-form-item prop="DeliveryDistance">
								<el-input v-model="ruleForm.DeliveryDistance" style="width: 100px;margin: 0 5px 5px;">
								</el-input>
							</el-form-item>
							公里以内（含），均可配送
						</div>
					</el-form-item>
					<el-form-item label="配送时间段" prop="DeliveryStartTime">
						<el-time-picker v-model="ruleForm.DeliveryStartTime"
							format="HH:mm" value-format="HH:mm" placeholder="配送开始时间">
						</el-time-picker>
						<span style="padding: 0 20px;color:#666">至</span>
						<el-time-picker v-model="ruleForm.DeliveryEndTime"
							format="HH:mm" value-format="HH:mm" placeholder="配送结束时间">
						</el-time-picker>
					</el-form-item>
					<el-form-item label="即时达">
						<el-checkbox v-model="ruleForm.InstantType">开启即时达</el-checkbox>
						<div style="line-height: 1.5;font-size: 12px;color: #999;">
							买家下单选择同城配送服务时，根据购买时间、备货时长、配送时长计算预计送达时间
							<!-- <el-popover placement="top-start" title="" width="200" trigger="hover">
								<img src="../../assets/img/20210717185340.png"
									style="display: block;width: 100%;"></img>
								<el-link type="primary" style="font-size: 12px;margin-left: 5px;" slot="reference">查看示例
								</el-link>
							</el-popover> -->
						</div>
					</el-form-item>
					<el-form-item label="定时达">
						<el-checkbox v-model="ruleForm.IsOpenTimeDelivery">开启定时达</el-checkbox>
						<div style="line-height: 1.5;font-size: 12px;color: #999;">
							买家下单选择同城配送服务时，可以在门店配送时间段内，选择期望送达时间    
							<el-button type="text" style="margin:0px;padding:0px" @click="looksampleShow=true">查看示例</el-button>
						</div>
					</el-form-item>
					<div v-if="ruleForm.IsOpenTimeDelivery">
						<el-form-item label="时段细分">
							<el-radio v-model="ruleForm.TimeSegmentType" :label="1">按半小时细分</el-radio>
							<el-radio v-model="ruleForm.TimeSegmentType" :label="2">按小时细分</el-radio>
							<div style="line-height: 1.5;font-size: 12px;color: #999;">
								比如配送时间段是10:00~22:00，按半小时细分，买家下单可选择10:00~10：30，10:30~11:00，以此类推；按小时细分，可选择10:00~11:00，11:00~12:00，以此类推    
							</div>
						</el-form-item>
						<el-form-item label="预约下单">
							<el-radio v-model="ruleForm.MakeppointmentOrderDaystype" :label="0">仅可当天下单</el-radio>
							<div style="line-height: 1.5;font-size: 12px;color: #999;">
								客户下单时，期望送达时间仅可选择当天的时间    
							</div>
							
							<div style="min-height:40px;margin-top:10px">
								<el-radio v-model="ruleForm.MakeppointmentOrderDaystype" :label="1">
									<span style="color:#606266">可提前</span>
									<el-select v-model="ruleForm.MakeppointmentOrderDays" style="width:100px;margin:0px 10px">
										<el-option :value="1" label="1天"></el-option>
										<el-option :value="2" label="2天"></el-option>
									</el-select>
									<span style="color:#606266">下单</span>
								</el-radio>
								<div style="margin-top:5px">
									<div style="line-height: 1.5;font-size: 12px;color: #999;">
										客户下单时，期望送达时间可选择当天、第2天或第3天的时间配送。    
									</div>
									<div style="line-height: 1.5;font-size: 12px;color: #999;">
										比如设置可提前1天下单，今天是5月6号，客户下单时可选择5月6号、5月7号的配送时间    
									</div>
								</div>
							</div>
						</el-form-item>
					</div>
					<el-form-item label="备货时长" prop="StockingTime">
						<el-input v-model="ruleForm.StockingTime" style="width: 100px;margin: 0 5px;"></el-input>分钟
						<div style="line-height: 1.5;font-size: 12px;color: #999;margin-top: 10px;margin-left: 3px;">
							买家下单，预计送达时间=购买时间+备货时长+配送时长</div>
					</el-form-item>
					<el-form-item label="配送时长" required>
						<div class="flex-box">
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.DeliveryTimeInitKm">
								</el-input>
							</el-form-item>
							公里以内，配送时长
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.InitKmDeliveryTime">
								</el-input>
							</el-form-item>
							分钟，每超出1公里，配送时长增加
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.PerBeyondKmDeliveryTime">
								</el-input>
							</el-form-item>
							分钟
						</div>
					</el-form-item>
					<el-form-item label="续重收费">
						<div class="flex-box">
							订单内商品总重量在
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.FirstWeightToNumber">
								</el-input>
							</el-form-item>
							kg内，不额外收费，每超出
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.ContinueWeightToNumber">
								</el-input>
							</el-form-item>
							kg，额外增收
							<el-form-item>
								<el-input style="width: 100px;margin: 0 5px 5px;" v-model="ruleForm.ContinueWeightMoney">
								</el-input>
							</el-form-item>
							元
						</div>
						<div style="line-height: 1.5;font-size: 12px;color: #999;">
							买家下单，最终支付配送费=收费标准+续重收费。若需要续重收费，请前往商品管理页面，设置商品重量
						</div>
					</el-form-item>
					<el-form-item label="同城配送方式">
						<el-checkbox :value="true" :disabled="true">门店自配送</el-checkbox>
						<br/>
						<div v-if="!istest||(istest&&[602,134,1,196,8].includes(MallId))">
							<el-checkbox :disabled="!ruleForm.IsSetMainCategory" v-model="ruleForm.IsOpenThirdPartyDelivery">第三方配送</el-checkbox>
							<div style="line-height: 1.5;font-size: 12px;color: #f56c6c;" v-if="!ruleForm.IsSetMainCategory">
								请先设置商城主营品类；品类影响服务商配送费，请根据实际情况选择。
								<el-button type="text" @click="toset" style="font-size:12px">前往设置</el-button>
								<el-button type="text" style="margin-left:30px;font-size:12px" @click="initData(true)">已设置，点击刷新</el-button>
							</div>
							<div style="line-height: 1.5;font-size: 12px;color: #999;">
								1.开启后，您可以选择开通第三方配送服务商，为门店配送订单
							</div>
							<div style="line-height: 1.5;font-size: 12px;color: #999;">
								2.第三方配送产生的配送费将从商城账户余额中扣除，若余额不足，服务商呼叫失败，请保证商城账户余额充足
							</div>
							<!-- <div style="line-height: 1.5;font-size: 12px;color: #999;">
								3.开启后，即表示已同意
								<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnFTSjW8azZCuzXFkdhGNxxe" :underline="false"
								target="_blank" type="primary">《{{agreementmsg.AgreementTitle}}》</el-link>
							</div> -->
						</div>
					</el-form-item>
					<el-form-item label="配送公司" v-if="ruleForm.IsOpenThirdPartyDelivery">
						<el-table :data="ruleForm.ShopInCityDeliveryCompanyList" style="width:600px" ref="table">
							<el-table-column prop="InCityDeliveryCompanyTypeValue" label="配送公司"></el-table-column>
							<el-table-column prop="" label="服务状态">
								<template slot-scope="scope">
									<div :style="{color:scope.row.IsOpen?'#67C23A':'#909399'}">
										{{scope.row.IsOpen?'已开通':'未开通'}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="" label="操作" width="100">
								<template slot-scope="scope">
									<el-button type="text" :style="{color:scope.row.IsOpen?'#f56c6c':'#409eff'}" @click="changeServe(scope.row)">
										{{scope.row.IsOpen?'关闭服务':'开通服务'}}
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>

				</div>

				<!-- <el-form-item label="运营成本" prop="price" required>
					<el-input v-model="ruleForm.price" placeholder="请输入门店运营成本,便于月末与门店进行核算" style="width: 495px;"></el-input><span style="color:#666;padding-left:10px">元</span>
				</el-form-item> -->
			</el-form>
		</div>
		<!-- 底部保存 -->
		<div class="bottom-save-btn">
							<!-- <el-button type="text" @click="toset">前往设置</el-button> -->
			<el-button style="width:240px" @click="canselGoods">取消</el-button>
			<el-button type="primary" style="width:240px" @click="handleSave" :loading="Savestoreloading">保存</el-button>
		</div>
		<!-- <div class="footer">
			<el-button round @click="canselGoods">取消</el-button>
			<el-button round type="primary" @click="handleSave" :loading="Savestoreloading">保存</el-button>
		</div> -->
		

		<select-address :visible="showSelectAddress"
			:longLat="ruleForm.lon && ruleForm.lat ? [ ruleForm.lat,ruleForm.lon]:[]"
			:districtList="ruleForm.addressList" :addressStr="ruleForm.Address" @close="showSelectAddress=false"
			@change="addressChange">
		</select-address>
	
		<el-dialog :visible.sync="looksampleShow" title="定时达示例查看" width="600px">
			<div style="display:flex;justify-content: space-between;width:100%">
				<div>
					<div style="line-height: 1.5;font-size: 14px;color: #606266;">定时达-按半小时细分</div>
					<img :src="imgurl+'/image/AddStore-look1.png'" style="width:230px" alt="">
				</div>
				<div>
					<div style="line-height: 1.5;font-size: 14px;color: #606266;">定时达-按小时细分</div>
					<img :src="imgurl+'/image/AddStore-look3.png'" style="width:230px" alt="">
				</div>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="errorShow" title="开通失败" width="500px" custom-class="dialogpad">
			<div style="display:flex;flex-direction:column;align-items:center">
				<div style="line-height: 1.5;font-size: 14px;color: #606266;">
					门店所在城市暂无配送点，第三方配送服务开通失败。请联系客服处理
				</div>
				<img src="@/assets/img/20210719171848.jpg" style="width:226px;margin:20px auto" alt="">
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import axios from 'axios'

	import {
		GetStoretagList,
		shopedit,
		shopinfo,
		employeelistbystate,
		shopvaliteShopThirdPartyDelivery
	} from '@/api/TurnTomySelf'
	import {
		mallsystemAgreementInfoInit
	} from "@/api/sv1.0.0"
	import other from "@/api/other"
	import {
		shoptaglist
	} from '@/api/api';
	import config from '@/config/index';
	import selectAddress from './components/selectAddress.vue';
	
	import {
		mapGetters
	} from 'vuex'

	export default {
		components: {
			selectAddress
		},
		computed: {
			...mapGetters([
				'ComponentId'
			]),
		},
		data() {

			var checkDistance = (rule, value, callback) => {
				var valStr = (value + '').trim();
				if (value === '' || value === '0' || value === 0 || isNaN(value)) {
					callback(new Error('公里数请设置在0.1~100之间，最多1位小数'));
				} else if (valStr.split(".")[1] && valStr.split(".")[1].length>1) {
					callback(new Error('公里数请设置在0.1~100之间，最多1位小数'));
				} else if (+value < 0.1 || +value > 100) {
					callback(new Error('公里数请设置在0.1~100之间，最多1位小数'));
				} else {
					callback();
				}
			};
			var checkPrice = (rule, value, callback) => {
				var head = '';
				if (rule.field == 'StartPrice'){
					head = '起送价'
				}
				if(rule.field == 'InitKmDeliveryFee' || rule.field == 'PerBeyondKmDeliveryFee'){
					head = '费用'
				}
				var valStr = (value + '').trim();
				var valInt = +value;
				if (valStr === '' || isNaN(value)) {
					callback(new Error(head + '请设置在0~999之间，最多2位小数'));
				} else if (valStr.split(".")[1] && valStr.split(".")[1].length>2) {
					callback(new Error(head + '请设置在0~999之间，最多2位小数'));
				} else if (valInt < 0 || valInt > 999){
					callback(new Error(head + '请设置在0~999之间，最多2位小数'));
				} else {
					callback();
				}
			};
			
			var checkTime = (rule, value, callback) => {
				if (!this.ruleForm.DeliveryStartTime || !this.ruleForm.DeliveryEndTime){
					callback(new Error('请选择配送时间'));
					return;
				}
				
				var bStart = this.ruleForm.startTime.split(':');
				var bEnd = this.ruleForm.endTime.split(':');
				var bStartInt = +(bStart[0]+bStart[1]);
				var bEndInt = +(bEnd[0]+bEnd[1]);
				
				var start = this.ruleForm.DeliveryStartTime.split(':');
				var end = this.ruleForm.DeliveryEndTime.split(':');
				var startInt = +(start[0]+start[1]);
				var endInt = +(end[0]+end[1]);
				
				if (startInt >= endInt){
					callback(new Error('开始时间需早于结束时间'));
					return;
				}
				if (startInt < bStart || endInt > bEndInt){
					callback(new Error('配送时间段请勿超出门店营业时间范围'));
					return;
				}
				callback();
			};
			
			var checkStockingTime = (rule, value, callback) => {
				if (!value || isNaN(value)){
					callback(new Error('备货时长请输入在1~60分钟之间，整数'));
					return;
				}
				
				var valStr = (value + '').trim();
				if (valStr.split(".")[1]) {
					callback(new Error('备货时长请输入在1~60分钟之间，整数'));
					return;
				}
				var valInt = +value;
				if (valInt < 1 || valInt > 60){
					callback(new Error('备货时长请输入在1~60分钟之间，整数'));
					return;
				}
				
				callback();
			};
			
			var checkBsTime = (rule, value, callback) => {
				
				if (!value){
					callback('请选择营业时间');
					return;
				}
				
				if (this.ruleForm.startTime && this.ruleForm.endTime){
					var bStart = this.ruleForm.startTime.split(':');
					var bEnd = this.ruleForm.endTime.split(':');
					var bStartInt = +(bStart[0]+bStart[1]);
					var bEndInt = +(bEnd[0]+bEnd[1]);
					
					if (bStartInt >= bEndInt){
						callback(new Error('开始时间需早于结束时间'));
						return;
					}
				}
				
				callback();
			};
			
			var checkcodePhone = (rule, value, callback) => {
				var valStr = (this.ruleForm.areaCode + '').trim();
				// let phone=  (this.ruleForm.phone+'').trim()
				// console.log(this.ruleForm.areaCode,!valStr,1)
				// if(!this.ruleForm.areaCode){
				// 	callback()
				// 	return 
				// }
				if (valStr.split(".")[1]) {
					callback(new Error('格式错误'));
					return;
				}
				if (valStr && isNaN(valStr)){
					callback(new Error('格式错误'));
					return;
				}
				
				callback();
			};
			var checkPhone = (rule, value, callback) => {
				var valStr = (this.ruleForm.phone + '').trim();
				// console.log(valStr,2)
				// if(!this.ruleForm.areaCode&&!this.ruleForm.phone){
				// 	callback(new Error('格式错误'));
				// 	return;
				// }
				if (valStr.split(".")[1]) {
					callback(new Error('格式错误'));
					return;
				}
				if (valStr && isNaN(valStr)){
					callback(new Error('格式错误'));
					return;
				}
				
				callback();
			};
			
			var checkSeedType = (rule, value, callback) => {
				
				if (!this.ruleForm.IsOpenExpress && !this.ruleForm.IsOpenSelfExtract && !this.ruleForm.IsOpenInCityDelivery){
					callback(new Error('至少选中一种配送方式'));
					return;
				}
				
				callback();
			};
			
			return {
				gourl:config.GO_URL,
				imgurl:config.IMG_BASE,
				pickerOption1: {},
				Savestoreloading: false,
				timeDefaultShow: null,
				id: 0,
				dataList: [],
				tagList: [],

				ruleForm: {
					ShopName: '',
					ProvinceId: '',
					CityId: '',
					AreadId: '',
					Province: '',
					City: '',
					Area: '',
					Address: '',
					startTime: '',
					endTime: '',
					tag: null,
					phone: '',
					areaCode: '',
					price: '',
					addressList: [],
					ShopNo: '',

					lon: '',
					lat: '',
					IsOpenExpress: true,
					IsOpenSelfExtract: false,
					IsOpenInCityDelivery: false,
					Principals: [],
					InCityDeliveryType: 0,
					ChargesStandard: 0,
					StartPrice: '',
					DeliveryDistance: '',
					DeliveryStartTime: '',
					DeliveryEndTime: '',
					InstantType: true,
					StockingTime: '30',
					DeliveryTimeInitKm: '',
					InitKmDeliveryTime: '',
					PerBeyondKmDeliveryTime: '',
					DeliveryFeeInitKm: '',
					InitKmDeliveryFee: '',
					DeliveryFeePerBeyondKm: '',
					PerBeyondKmDeliveryFee: '',

					IsOpenTimeDelivery:false,
					TimeSegmentType:1,
					MakeppointmentOrderDaystype:0,
					MakeppointmentOrderDays:1,
					FirstWeightToNumber:0,
					ContinueWeightToNumber:0,
					ContinueWeightMoney:0,
					IsOpenThirdPartyDelivery:false,
					IsSetMainCategory:false,
					ShopInCityDeliveryCompanyList:[],
				},
				rules: {
					ShopName: [{
							required: true,
							message: '请输入门店名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					addressList: [{
						required: true,
						message: '请选择地址',
						trigger: 'change'
					}],
					Address: [{
						required: true,
						message: '请填写详细地址，并在地图的搜索结果中选取坐标',
						trigger: 'blur'
					}],
					startTime: [{
						required: true,
						validator: checkBsTime,
						trigger: 'blur'
					}],
					endTime: [{
						required: true,
						validator: checkBsTime,
						trigger: 'blur'
					}],
					areaCode: [{
						required: true,
						validator: checkcodePhone,
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						validator: checkPhone,
						trigger: 'blur'
					}],
					// price: [{
					// 		required: true,
					// 		message: '请输入运营成本',
					// 		trigger: 'blur'
					// 	}
					// ],
					DeliveryFeeInitKm: [{
						required: true,
						trigger: 'blur',
						validator: checkDistance
					}],
					DeliveryFeePerBeyondKm: [{
						required: true,
						trigger: 'blur',
						validator: checkDistance
					}],
					DeliveryTimeInitKm: [{
						required: true,
						trigger: 'blur',
						validator: checkDistance
					}],
					DeliveryDistance: [{
						required: true,
						trigger: 'blur',
						validator: checkDistance
					}],
					StartPrice: [{
						required: true,
						trigger: 'blur',
						validator: checkPrice
					}],
					InitKmDeliveryFee: [{
						required: true,
						trigger: 'blur',
						validator: checkPrice
					}],
					PerBeyondKmDeliveryFee: [{
						required: true,
						trigger: 'blur',
						validator: checkPrice
					}],
					DeliveryStartTime: [{
						required: true,
						trigger: 'change',
						validator: checkTime
					}],
					StockingTime: [{
						required: true,
						trigger: 'blur',
						validator: checkStockingTime
					}],
					IsOpenExpress: [{
						required: true,
						trigger: 'change',
						validator: checkSeedType
					}],
				},
				changeTime: '',
				Startime1: '',
				Endtime1: '',
				employeeList: [], //员工列表
				trueAddress: true,

				showSelectAddress: false, //选择地址弹框
				
				showCitySetting: false,
				looksampleShow:false,
				companylist:[],
				errorShow:false,
				agreementmsg:{},
				istest:false,
				MallId:0,
			}
		},
		created() {
			let d = new Date();
			let year = d.getFullYear();
			let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
			let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
			this.timeDefaultShow = new Date(year, month, day, 0, 0)
			
			var mallkey = localStorage.getItem('mlmzMallKey');
			if (process.env.NODE_ENV == 'production'){
				if (mallkey === 'yusheng' || mallkey === 'blspc'){
					this.showCitySetting = true;
				}else{
					this.showCitySetting = false;
				}
			}else{
				this.showCitySetting = true;
			}
		},
		//初始化数据
		beforeMount() {},
		mounted() {
			this.testistest()
			this.id = this.$route.query.id||0;
			this.initData(this.id==0);
			this.getAgreementmsg()
			this.getTagList();
			this.getEmployeeList();

		},
		methods: {
			testistest(){
				this.istest = process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage'
				this.MallId = Number(localStorage.getItem('MallId'))
				// console.log(this.istest,this.MallId,[602,134,1,196,8].includes(this.MallId))
			},
			toset(){
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				window.open(this.gourl+headsUrls+'/setting/index')
			},

			async getAgreementmsg(){
				try{
					let res = await mallsystemAgreementInfoInit({
						AgreementType:9,
						MallId:this.MallId,
					})
					if(res.IsSuccess){
						this.agreementmsg = res.Result
					}
				}finally{
					//
				}
			},
			async changeServe(row){
				try{
					let IsOpen = await row.IsOpen
					if(IsOpen){
						this.ruleForm.ShopInCityDeliveryCompanyList = this.ruleForm.ShopInCityDeliveryCompanyList.map(v=>{
							if(v.Id==row.Id){
								v.IsOpen = false
							}
							return v
						})
						this.$nextTick(()=>{
							this.$refs.table.doLayout()
						})
						this.$message.success('操作成功')
						return 
						
					}
					let res = await shopvaliteShopThirdPartyDelivery({
						InCityDeliveryCompanyType:await row.InCityDeliveryCompanyType,
						Province:this.ruleForm.Province,
						City:this.ruleForm.City,
						noError:true
					})
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.ruleForm.ShopInCityDeliveryCompanyList = this.ruleForm.ShopInCityDeliveryCompanyList.map(v=>{
							if(v.Id==row.Id){
								v.IsOpen = true
							}
							return v
						})
						this.$nextTick(()=>{
							this.$refs.table.doLayout()
						})
					}else if(!row.IsOpen && !res.IsSuccess){
						this.errorShow = true
					}
					// console.log(res)
					// if(res.IsSuccess){
					// 	this.errorShow = true
					// 	this.initData()
					// }
				}finally{
					//
				}
			},
			/**
			 * 获取员工列表
			 */
			async getEmployeeList() {
				try {
					const res = await employeelistbystate({
						// EmployeeState: 1
					});
					var list = [];
					res.Result.map(item => {
						if (item.EmployeeState === 1 || item.EmployeeState === 2){
							item.label = item.EmployeeName + '(' + item.Phone + ')';
							list.push(item);
						}
					});
					
					this.employeeList = list;
				} catch (e) {
					//TODO handle the exception
				}
			},

			changeEndtime(val) {
				if (val) {
					if (this.ruleForm.startTime && val == this.ruleForm.startTime) {
						this.ruleForm.endTime = ''
						this.$message({
							showClose: true,
							type: 'error',
							message: '开始时间不能等于结束时间'
						});
					}
					this.pickerOption1 = {
						selectableRange: '00:00' + '-' + val
					}
				}
			},
			onTimeChange(val) {
				if (val == this.ruleForm.endTime && this.ruleForm.endTime) {
					this.ruleForm.startTime = ''
					this.$message({
						showClose: true,
						type: 'error',
						message: '开始时间不能等于结束时间'
					});
				}
			},
			// 取消操作
			canselGoods() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/door/doorManager'
					});
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {

				})
			},
			async initData(isrefresh) {
				let companylist = await other.enumList({
					EnumName:'EnumInCityDeliveryCompanyType'
				})
				if(companylist.IsSuccess){
					this.companylist = companylist.Result
				}

				const res = await shopinfo({
					Id: this.id
				})

				if (res.IsSuccess) {
					//刷新或者id为0
					if(isrefresh){
						// this.ruleForm.IsOpenThirdPartyDelivery = true
						this.ruleForm.IsSetMainCategory = res.Result.IsSetMainCategory
						this.ruleForm.ShopInCityDeliveryCompanyList = res.Result.ShopInCityDeliveryCompanyList
						// console.log(this.ruleForm.IsSetMainCategory,123)
						return 
					}

					var obj = res.Result;

					obj.ShopInCityDeliveryCompanyList = obj.ShopInCityDeliveryCompanyList.map(v=>{
						let data = this.companylist.find(x=>x.Value==v.InCityDeliveryCompanyType)
						return {
							InCityDeliveryCompanyType:v.InCityDeliveryCompanyType,
							InCityDeliveryCompanyTypeValue:data.Name,
							IsOpen:v.IsOpen
						}
					})

					if (obj.ShopTagId == 0) {
						obj.ShopTagId = ''
					}
					
					this.ruleForm.ShopName = obj.ShopName;
					this.ruleForm.ProvinceId = obj.ProvinceId;
					this.ruleForm.CityId = obj.CityId;
					this.ruleForm.AreadId = obj.AreaId;
					this.ruleForm.Province = obj.Province;
					this.ruleForm.City = obj.City;
					this.ruleForm.Area = obj.Area;
					this.ruleForm.Address = obj.Address;
					this.ruleForm.startTime = obj.BusinessStartTime;
					this.ruleForm.endTime = obj.BusinessEndTime;
					this.ruleForm.tag = obj.ShopTagId;
					this.ruleForm.phone = obj.ContactPhone === '' ? undefined : obj.ContactPhone;
					this.ruleForm.areaCode = obj.AreaCode;
					this.ruleForm.price = obj.OperateCost;
					this.ruleForm.addressList = [obj.ProvinceId + '', obj.CityId + '', obj.AreaId + ''];
					this.ruleForm.ShopNo = obj.ShopNo;
					this.ruleForm.lon = obj.Longitude;
					this.ruleForm.lat = obj.Latitude;
					this.ruleForm.IsOpenExpress = obj.IsOpenExpress;
					this.ruleForm.IsOpenSelfExtract = obj.IsOpenSelfExtract;
					this.ruleForm.IsOpenInCityDelivery = obj.IsOpenInCityDelivery;
					this.ruleForm.Principals = obj.Principals;
					this.ruleForm.ShopInCityDeliveryCompanyList = obj.ShopInCityDeliveryCompanyList
					this.ruleForm.IsSetMainCategory = obj.IsSetMainCategory//不管是否选中同城配送 这里都要赋值
					
					if (obj.IsOpenInCityDelivery){
						this.ruleForm.InCityDeliveryType = obj.InCityDeliveryType;
						this.ruleForm.ChargesStandard = obj.ChargesStandard;
						this.ruleForm.DeliveryStartTime = obj.DeliveryStartTime;
						this.ruleForm.DeliveryEndTime = obj.DeliveryEndTime;
						this.ruleForm.InstantType = obj.InstantType==0?true:false;
						this.ruleForm.StockingTime = obj.StockingTime;
						this.ruleForm.DeliveryTimeInitKm = obj.DeliveryTimeInitKm;
						this.ruleForm.InitKmDeliveryTime = obj.InitKmDeliveryTime;
						this.ruleForm.PerBeyondKmDeliveryTime = obj.PerBeyondKmDeliveryTime;
						this.ruleForm.DeliveryFeeInitKm = obj.DeliveryFeeInitKm;
						this.ruleForm.InitKmDeliveryFee = obj.InitKmDeliveryFee;
						this.ruleForm.DeliveryFeePerBeyondKm = obj.DeliveryFeePerBeyondKm;
						this.ruleForm.PerBeyondKmDeliveryFee = obj.PerBeyondKmDeliveryFee;
						this.ruleForm.StartPrice;
						this.ruleForm.DeliveryDistance;
						this.ruleForm.IsOpenTimeDelivery = obj.IsOpenTimeDelivery;
						this.ruleForm.TimeSegmentType = obj.IsOpenTimeDelivery?obj.TimeSegmentType:1;
						this.ruleForm.MakeppointmentOrderDays = obj.IsOpenTimeDelivery&&obj.MakeppointmentOrderDays?obj.MakeppointmentOrderDays:1;
						this.ruleForm.MakeppointmentOrderDaystype = obj.IsOpenTimeDelivery&&obj.MakeppointmentOrderDays?1:0;
						this.ruleForm.FirstWeightToNumber = obj.FirstWeightToNumber||0;
						this.ruleForm.ContinueWeightToNumber = obj.ContinueWeightToNumber||0;
						this.ruleForm.ContinueWeightMoney = obj.ContinueWeightMoney||0;
						this.ruleForm.IsOpenThirdPartyDelivery = obj.IsOpenThirdPartyDelivery
						
						
						if (obj.ShopStartingPriceConfigList && obj.ShopStartingPriceConfigList.length) {
							this.ruleForm.StartPrice = obj.ShopStartingPriceConfigList[0].StartPrice;
							this.ruleForm.DeliveryDistance = obj.ShopStartingPriceConfigList[0].DeliveryDistance;
						}
					}


					

					if (this.ruleForm.endTime) {
						this.pickerOption1 = {
							selectableRange: '00:00:00' + '-' + this.ruleForm.endTime
						}
					}
					
					if (this.ruleForm.Province){
						this.getDistrict();
					}


				}

			},
			getDistrict(){
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime()).then(res => {
					
					// var list = [{
					// 	"id": "1",
					// 	"name": "华东",
					// 	"child": []
					// },{
					// 	"id": "2",
					// 	"name": "华南",
					// 	"child": []
					// },{
					// 	"id": "3",
					// 	"name": "华中",
					// 	"child": []
					// },{
					// 	"id": "4",
					// 	"name": "华北",
					// 	"child": []
					// },{
					// 	"id": "5",
					// 	"name": "西北",
					// 	"child": []
					// },{
					// 	"id": "6",
					// 	"name": "西南",
					// 	"child": []
					// },{
					// 	"id": "7",
					// 	"name": "东北",
					// 	"child": []
					// },{
					// 	"id": "8",
					// 	"name": "港澳台",
					// 	"child": []
					// }]
					
					// res.data.map(item=>{
					// 	if (['山东省', '江苏省', '上海市', '浙江省', '安徽省', '福建省', '江西省'].indexOf(item.name)>-1){
					// 		list[0].child.push(item)
					// 	}
					// 	if (['广东省', '广西壮族自治区', '海南省'].indexOf(item.name)>-1){
					// 		list[1].child.push(item)
					// 	}
					// 	if (['河南省', '湖南省', '湖北省'].indexOf(item.name)>-1){
					// 		list[2].child.push(item)
					// 	}
					// 	if (['北京市', '天津市', '河北省', '山西省', '内蒙古自治区'].indexOf(item.name)>-1){
					// 		list[3].child.push(item)
					// 	}
					// 	if (['宁夏回族自治区', '青海省', '陕西省', '甘肃省', '新疆维吾尔自治区'].indexOf(item.name)>-1){
					// 		list[4].child.push(item)
					// 	}
					// 	if (['四川省', '贵州省', '云南省', '重庆市', '西藏自治区'].indexOf(item.name)>-1){
					// 		list[5].child.push(item)
					// 	}
					// 	if (['辽宁省', '吉林省', '黑龙江省'].indexOf(item.name)>-1){
					// 		list[6].child.push(item)
					// 	}
					// 	if (['香港特别行政区', '澳门特别行政区', '台湾'].indexOf(item.name)>-1){
					// 		console.log(item.name)
					// 		list[7].child.push(item)
					// 	}
					// })
					// console.log(list)
					// console.log(JSON.stringify(list))
					this.districtList = res.data;
					this.checkDistrict();
					
				})
			},
			checkDistrict(){
				var p = '';
				var c = '';
				var a = '';
				this.districtList.map(item=>{
					if (this.ruleForm.Province === item.name){
						p = item.name;
						
						item.child.map(item1=>{
							
							if (this.ruleForm.City === item1.name){
								c = item.name;
								
								item1.child.map(item2=>{
									if (this.ruleForm.Area === item2.name){
										a = item.name;
									}
								})
							}
						})
					}
				})
				
				if (p && c && a){
					this.trueAddress = true;
				}else{
					this.trueAddress = false;
					this.ruleForm.addressList = [];
				}
			},
			async getTagList() {
				const res = await shoptaglist();

				if (res.IsSuccess) {
					this.tagList = res.Result;
				}
			},
			addressChange(record) {
				// console.log(record,1)
				for (var item in record) {
					this.ruleForm[item] = record[item]
				}
				// console.log(record)
				this.trueAddress = true;
			},

			checkFirstWeightToNumber(key){
				var value = this.ruleForm[key];
				var valStr = (value+'').trim();
				// console.log(valStr)
				if(valStr && (valStr<0 || valStr>50 || valStr!=Number(valStr))){
					this.$message.error('重量请输入0~50之间，最多2位小数')
					return false
				}else if(valStr.split(".")[1] && valStr.split(".")[1].length>2){
					this.$message.error('重量请输入0~50之间，最多2位小数');
					return false;
				}else {
					return true
				}
			},
			checkContinueWeightMoney(key){
				var value = this.ruleForm[key];
				var valStr = (value+'').trim();
				if(valStr && (valStr<0 || valStr>999 || valStr!=Number(valStr))){
					this.$message.error('超重增收请输入0~999之间，最多2位小数')
					return false
				}else if(valStr.split(".")[1] && valStr.split(".")[1].length>2){
					this.$message.error('超重增收请输入0~999之间，最多2位小数');
					return false;
				}else {
					return true
				}
			},
			checkdelivery(){
				if(this.ruleForm.IsOpenInCityDelivery&&!this.ruleForm.InstantType&&!this.ruleForm.IsOpenTimeDelivery){
					this.$message.error('即时达与定时达不可同时关闭')
					return false
				}
				return true
			},
			
			checkDistance(key){
				var value = this.ruleForm[key];
				var valStr = (value+'').trim();
				if (value === '' || value === '0' || value === 0 || isNaN(value)) {
					this.message('公里数请设置在0.1~100之间，最多1位小数');
					return false;
				} else if (valStr.split(".")[1] && valStr.split(".")[1].length>1) {
					this.message('公里数请设置在0.1~100之间，最多1位小数');
					return false;
				} else if (+value < 0.1 || +value > 100) {
					this.message('公里数请设置在0.1~100之间，最多1位小数');
					return false;
				} else {
					return true;
				}
			},
			checkPrice(key){
				var value = this.ruleForm[key];
				var head = '';
				if (key == 'StartPrice'){
					head = '起送价'
				}
				if(key == 'InitKmDeliveryFee' || key == 'PerBeyondKmDeliveryFee'){
					head = '费用'
				}
				var valStr = (value + '').trim();
				var valInt = +value;
				if (valStr === '' || isNaN(value)) {
					this.message(head + '请设置在0~999之间，最多2位小数');
					return false;
				} else if (valStr.split(".")[1] && valStr.split(".")[1].length>2) {
					this.message(head + '请设置在0~999之间，最多2位小数');
					return false;
				} else if (valInt < 0 || valInt> 999) {
					this.message(head + '请设置在0~999之间，最多2位小数');
					return false;
				}else {
					return true;
				}
			},
			checkTime(key){
				var value = this.ruleForm[key];
				var valStr = (value + '').trim();
				var valInt = +value;
				if (valStr === '' || value === 0 || value === '0' || isNaN(value)) {
					this.message('时长请设置在1~180分钟之间，整数');
					return false;
				} else if (valStr.split(".")[1]) {
					this.message('时长请设置在1~180分钟之间，整数');
					return false;
				} else if (valInt < 1 || valInt > 180) {
					this.message('时长请设置在1~180分钟之间，整数');
					return false;
				}else {
					return true;
				}
			},
			message(str){
				this.$message({
					showClose: true,
					type: 'error',
					message: str
				})
			},
			checkItem(){
				if (this.checkDistance('DeliveryFeeInitKm') && 
					this.checkDistance('DeliveryFeePerBeyondKm') && 
					this.checkDistance('DeliveryTimeInitKm') && 
					this.checkPrice('InitKmDeliveryFee') && 
					this.checkPrice('PerBeyondKmDeliveryFee') && 
					this.checkTime('InitKmDeliveryTime') && 
					this.checkTime('PerBeyondKmDeliveryTime') && 
					this.checkFirstWeightToNumber('FirstWeightToNumber') && 
					this.checkFirstWeightToNumber('ContinueWeightToNumber') && 
					this.checkContinueWeightMoney('ContinueWeightMoney') && 
					this.checkdelivery()
				){
					return true;
				}
				
				document.documentElement.scrollTop = 1000;
				return false;
			},
			handleSave() {
				
				if (!this.trueAddress){
					this.message('请重新选择省市区');
					return;
				}
				
				if (this.ruleForm.IsOpenInCityDelivery && !this.checkItem()){
					return
				}
				
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.Savestoreloading = true
						// if (this.ruleForm.phone == '') {
						// 	this.$message({
						// 		showClose: true,
						// 		type: 'error',
						// 		message: '请输入联系电话'
						// 	})
						// 	this.Savestoreloading = false
						// 	return
						// }
						
						this.saveData();
					} else {
						this.$common.completeFormInformation('请完善门店信息', 'is-error')
						console.log('error submit!!');
						return false;
					}
				});
			},
			async saveData() {
				try {
					// if (!this.ruleForm.lon || !this.ruleForm.lat) {
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'error',
					// 		message: '请点击搜索并确认门店位置'
					// 	})
					// 	this.Savestoreloading = false
					// 	return;
					// }

					var obj = this.ruleForm;
						// console.log(obj.FirstWeightToNumber,obj.ContinueWeightToNumber,obj.ContinueWeightMoney)
					var params = {
						noError:true,
						Id: this.id,
						ShopName: obj.ShopName,
						ShopNo: obj.ShopNo||'',
						ShopTagId: obj.tag,
						ProvinceId: obj.ProvinceId,
						CityId: obj.CityId,
						AreaId: obj.AreadId,
						Province: obj.Province,
						City: obj.City,
						Area: obj.Area,
						Address: obj.Address,
						Longitude: obj.lon,
						Latitude: obj.lat,
						BusinessStartTime: obj.startTime,
						BusinessEndTime: obj.endTime,
						AreaCode: obj.areaCode || '',
						ContactPhone: obj.phone || '',
						OperateCost: obj.price,
						IsOpenExpress: obj.IsOpenExpress,
						IsOpenSelfExtract: obj.IsOpenSelfExtract,
						IsOpenInCityDelivery: obj.IsOpenInCityDelivery,

					}
					
					if(obj.IsOpenInCityDelivery || obj.IsOpenSelfExtract){
						params.Principals = obj.Principals;
					}
					
					if(obj.IsOpenInCityDelivery){
						params.InCityDeliveryType = obj.InCityDeliveryType;
						params.ChargesStandard = obj.ChargesStandard;
						params.DeliveryStartTime = obj.DeliveryStartTime;
						params.DeliveryEndTime = obj.DeliveryEndTime;
						params.InstantType = obj.InstantType?0:1;
						params.StockingTime = obj.StockingTime;
						params.DeliveryTimeInitKm = obj.DeliveryTimeInitKm;
						params.InitKmDeliveryTime = obj.InitKmDeliveryTime;
						params.PerBeyondKmDeliveryTime = obj.PerBeyondKmDeliveryTime;
						params.DeliveryFeeInitKm = obj.DeliveryFeeInitKm;
						params.InitKmDeliveryFee = obj.InitKmDeliveryFee;
						params.DeliveryFeePerBeyondKm = obj.DeliveryFeePerBeyondKm;
						params.PerBeyondKmDeliveryFee = obj.PerBeyondKmDeliveryFee;
						params.ShopStartingPriceConfigList = [{
							StartPrice: obj.StartPrice || '',
							DeliveryDistance: obj.DeliveryDistance || ''
						}]

						
						params.IsOpenTimeDelivery=obj.IsOpenTimeDelivery,
						params.TimeSegmentType=obj.IsOpenTimeDelivery?obj.TimeSegmentType:1,
						params.MakeppointmentOrderDays=obj.IsOpenTimeDelivery&&obj.MakeppointmentOrderDaystype?obj.MakeppointmentOrderDays:0,

						params.FirstWeightToNumber=obj.FirstWeightToNumber||0,
						params.ContinueWeightToNumber=obj.ContinueWeightToNumber||0,
						params.ContinueWeightMoney=obj.ContinueWeightMoney||0,
						params.IsOpenThirdPartyDelivery=obj.IsOpenThirdPartyDelivery,
						params.IsSetMainCategory=obj.IsSetMainCategory
						params.ShopInCityDeliveryCompanyList = obj.ShopInCityDeliveryCompanyList.map(v=>{
							v.IsOpen = obj.IsOpenThirdPartyDelivery?v.IsOpen:false
							return v
						})
					}
					
					// console.log(params.FirstWeightToNumber,params.ContinueWeightToNumber,params.ContinueWeightMoney)
					const res = await shopedit({
						ShopInfo: params
					})
					
					if (res.IsSuccess) {
						if (this.id > 0) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '编辑成功'
							})
						}else if(res.ErrorCode==20000014){
							this.errorShow = true
							return 
						}
						if (this.id == 0) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '添加成功'
							})

						}

						setTimeout(() => {
							this.$router.back();
							this.Savestoreloading = false
						}, 1000)

					}
				} catch (err) {
					this.Savestoreloading = false
				} finally {
					// this.Savestoreloading = false
				}

			},
			
			showToast(){
				if (!this.ruleForm.Province){
					this.$message.error('开启同城配送，请先设置门店地址')
				}
			}
		}
	}
</script>

<style lang="less" scoped>

.nostart ::v-deep .el-form-item__label:before{
	content: '' !important;
	
}
</style>

<style lang="less">
	
	// input[type=number]::-webkit-inner-spin-button {
	// 		-webkit-appearance: none;
	// 	}
	
	.textLeft .el-input__inner{
		text-align: left;
	}
	
	.StoreInfo-rule-form .el-form-item__error{
		white-space: nowrap;
	}
	
	.StoreInfo {
		background: #fff;
		font-size: 14px;
		color: #606266;

		.Title {
			padding: 10px;
			border-bottom: 1px solid #F0F2F5;
		}

		.Contain {
			padding: 20px;
		}
		
		
		
		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}
		
		.number__input {
			border: 1px solid #ccc;
		}
		
		.bm-view {
			width: 100%;
			height: 300px;
		}
		
		.time-box {
			display: flex;
			align-items: center;
		
			.time {
				width: 220px;
			}
		}
		
		.footer {
			display: flex;
			justify-content: center;
			padding: 0 0 30px;
		}
		
		.BaiduMap {
			position: relative;
		}
		.flex-box{
			display: flex;
			flex-wrap: wrap;
		}
	}
.dialogpad .el-dialog__body{
  padding: 10px 20px 20px 20px;
}



	
</style>
